import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Route, Routes } from 'react-router'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { Provider } from 'react-redux'
import './i18n'
import App from './App'
import ErrorPage from './components/ErrorPage'
import AuthPage from './components/AuthPage'
import store from './redux/store'
import CourseList from './components/Courses/CourseList'
import { createTheme, ThemeProvider } from '@mui/material'
import CourseDetails from './components/Courses/CourseDetails'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
})

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />}>
                <Route path="/" element={<CourseList />} />
                <Route path="/course/:id" element={<CourseDetails />} />
              </Route>
              <Route path="/auth" element={<AuthPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
