import './styles.scss'
import { useState } from 'react'
import { Link } from 'react-router'
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function Header() {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => setAnchorEl(event.currentTarget)
  const handleClose = (): void => setAnchorEl(null)

  return (
    <header className="header">
      <div className="container">
        <Link to="">
          <img src="/assets/images/kaz-logo.svg" className="logo" alt="Logo"></img>
        </Link>
        <nav className="nav">
          <Link to="">{t('header.courses')}</Link>
          <Link to="">{t('header.my-progress')}</Link>
        </nav>
        <IconButton
          className="menu-btn"
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          <img src="/assets/icons/menu.svg" alt="Menu" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          className="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem className="mb-32 row-gap-12" onClick={handleClose}>
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
            <div>
              <div className="account-menu__name">John Doe</div>
              <div className="fs-12">test@gmail.com</div>
            </div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="" className="account-menu__link">
              {t('header.admin-panel').toUpperCase()}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="" className="account-menu__link">
              {t('header.school').toUpperCase()}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="" className="account-menu__link">
              {t('header.courses')}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="" className="account-menu__link">
              {t('header.homework')}
            </Link>
          </MenuItem>
          <MenuItem className="mb-32">
            <Link to="" className="account-menu__link">
              {t('header.achievements')}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="" className="account-menu__link account-menu__link_quit">
              {t('header.quit')}
            </Link>
          </MenuItem>
        </Menu>
      </div>
    </header>
  )
}
