import './App.scss'
import Header from './components/shared/Header'
import { Outlet, useNavigate } from 'react-router'
import axios from 'axios'
import { useEffect } from 'react'

export default function App() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (!token) navigate('/auth')
  }, [])
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}
