import axios from 'axios'

function getCourses() {
  return axios.get('api/courses/search?prefLang=ru') // TODO replace hardcoded prefLang
}

function getCourseDetails(courseId: string) {
  return axios({
    method: 'get',
    url: `api/courses/details/${courseId}`,
  })
}

function enrollToCourse(id: string) {
  return axios({
    method: 'post',
    url: `/api/courses/${id}/participation`,
  })
}

export { getCourses, getCourseDetails, enrollToCourse }
