import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Participation {
  startDate: Date
  finishDate: Date
}

export interface Course {
  courseId: string
  courseLocales: CourseLocale[]
  isSaved?: boolean
  image?: string
  participation: Participation
}

interface CourseLocale {
  courseLocaleId: string
  title: string
  description: string
  courseModules: CourseModule[]
  image?: string
}

interface CourseModule {
  courseModuleId: string
  title: string
  courseExercises: CourseExercise[]
}

interface CourseExercise {
  courseExerciseId: string
  title: string
  type?: string
}

interface CoursesState {
  list: Course[]
  course: Course
  isLoading: boolean
  errors: string | string[]
}

const initialState: CoursesState = {
  list: [],
  course: {} as Course,
  isLoading: false,
  errors: '',
}

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    getCourses: (state): void => {
      state.isLoading = true
    },
    getCoursesSuccess: (state, action: PayloadAction<Course[]>): void => {
      state.isLoading = false
      state.list = action.payload
    },
    getCoursesError: (state, action: PayloadAction<string | string[]>): void => {
      state.isLoading = false
      state.errors = action.payload
    },
    getCourseDetails: (state, _): void => {
      state.isLoading = true
    },
    getCourseDetailsSuccess: (state, action: PayloadAction<Course>): void => {
      state.isLoading = false
      state.course = action.payload
    },
    getCourseDetailsError: (state, action: PayloadAction<string>): void => {
      state.isLoading = false
      state.errors = action.payload
    },
    enrollToCourse: (state, _): void => {
      state.isLoading = true
    },
    enrollToCourseSuccess: (state, action: PayloadAction<Participation>): void => {
      state.isLoading = false
      state.course.participation = action.payload
    },
    enrollToCourseError: (state, action: PayloadAction<string>): void => {
      state.isLoading = false
      state.errors = action.payload
    },
  },
})

export const coursesActions = coursesSlice.actions
export default coursesSlice.reducer
