import { put, call, takeEvery } from 'redux-saga/effects'
import { coursesActions } from './courseSlice'
import { enrollToCourse, getCourseDetails, getCourses } from '../../services/Courses'
import { PayloadAction } from '@reduxjs/toolkit'

export function* getCoursesSaga(): unknown {
  try {
    const courses = yield call(getCourses)
    yield put(coursesActions.getCoursesSuccess(courses))
  } catch (error: unknown) {
    yield put(coursesActions.getCoursesError(error as string))
  }
}

export function* getCourseDetailsSaga(action: PayloadAction<string>): unknown {
  try {
    const course = yield call(getCourseDetails, action.payload)
    yield put(coursesActions.getCourseDetailsSuccess(course.data))
  } catch (error: unknown) {
    yield put(coursesActions.getCourseDetailsError(error as string))
  }
}

export function* enrollToCourseSaga(action: PayloadAction<string>): unknown {
  try {
    const response = yield call(enrollToCourse, action.payload)
    yield put(coursesActions.enrollToCourseSuccess(response.data))
  } catch (error: unknown) {
    yield put(coursesActions.enrollToCourseError(error as string))
  }
}

export function* watchEnrollToCourseSaga() {
  yield takeEvery(coursesActions.enrollToCourse, enrollToCourseSaga)
}
export function* watchLoadCoursesSaga() {
  yield takeEvery(coursesActions.getCourses, getCoursesSaga)
}
export function* watchGetCourseDetailsSaga() {
  yield takeEvery(coursesActions.getCourseDetails, getCourseDetailsSaga)
}
